import React, { useState } from "react";
import "./TestimonialsSection.css";
import { useTranslation } from 'react-i18next';

const TestimonialsSection = () => {
  const { t } = useTranslation();
  const quotes = t('testimonies', { returnObjects: true });

  const [currentQuote, setCurrentQuote] = useState(0);

  const nextQuote = () => {
    setCurrentQuote((prevQuote) => (prevQuote + 1) % quotes.length);
  };

  const prevQuote = () => {
    setCurrentQuote(
      (prevQuote) => (prevQuote - 1 + quotes.length) % quotes.length
    );
  };

  const goToQuote = (index) => {
    setCurrentQuote(index);
  };

  return (
    <div className="testimonials-section">
      <h2 className="testimonials-title blue-color">{t('what_our_customers_say')}</h2>
      <img
        src="/images/quote-open.svg"
        alt="Quote Icon"
        className="quote-icon"
      />
      <div className="testimonial">
        <button className="arrow left-arrow" onClick={prevQuote}>
          <img
            src="/images/arrow-left.svg"
            alt="Left Arrow"
            width="50"
          />
        </button>

        <div className="testimonial-content">
          <p className="testimonial-quote">{quotes[currentQuote].text}</p>
          <div
            className={`customer-info ${
              quotes[currentQuote].profile_image ? "with-image" : "no-image"
            }`}>
            {quotes[currentQuote].profile_image && (
              <img
                src={`${quotes[currentQuote].profile_image}`}
                alt={quotes[currentQuote].name}
                className="profile-image"
              />
            )}
            <div className="customer-details">
              <p className="customer-name">{quotes[currentQuote].name}</p>
              <p className="customer-title">{quotes[currentQuote].title}</p>
              <p className="customer-company">{quotes[currentQuote].company}</p>
            </div>
          </div>
        </div>
        <button className="arrow right-arrow" onClick={nextQuote}>
          <img
            src="/images/arrow-right.svg"
            alt="Right Arrow"
            width="50"
          />
        </button>
      </div>
      <div className="dots">
        {quotes.map((_, index) => (
          <span
            key={index}
            className={`dot ${currentQuote === index ? "active" : ""}`}
            onClick={() => goToQuote(index)}></span>
        ))}
      </div>

    </div>

  );
};

export default TestimonialsSection;
