import React from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./LearnMore.css";

const LearnMore = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const submitted = queryParams.get("submitted");

  return (
    <div className="learn-more">
      <div className="content">
        {submitted && (
          <div className="top-message text-center">
            <h1>We Have Received Your Demo Request</h1>
            <p className="smaller">
              Our system expert is working hard to schedule you in for a
              dedicated Demo session, While you wait, take a look at our product
              tour video below.
            </p>
          </div>
        )}
        <div className="left-section">
          <video width="640" controls>
            <source
              src="https://static.ticketingbox.com/video/TB-Promo-1-2024.mp4"
              type="video/mp4"
            />
            {t("not_support_video_tag")}
          </video>
        </div>
        <div className="right-section">
          <h2>An Overview</h2>
          <div className="content-text">
            <p>
              Introducing Ticketingbox, a robust ticketing solution designed to
              streamline operations, drive revenue growth, and enhance customer
              satisfaction for theaters and event organizers.
            </p>
            <p>
              With an expansive reach and reputation for reliability,
              Ticketingbox has been chosen by a leading event organizer and
              manage ticket sales across approximately 200 theaters annually.
              This partnership underscores both the wide-reaching capabilities
              of our platform and the strong trust that clients place in our
              technology to effectively meet their diverse ticketing
              requirements.
            </p>
            <p>
              We're excited about the prospect of collaborating with you to
              propel your business forward!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LearnMore;
