import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./PricingRequestPage.css";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import formatTitle from "../utils/formatTitle";

const PricingRequestPage = () => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    workEmail: "",
    organization: "",
    annualSales: "",
    website: "",
    phone: "",
  });

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    if (!isFocused) {
      setFormData((prevState) => ({
        ...prevState,
        website: "https://",
      }));
      setIsFocused(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${backendUrl}/v1/pricing-request`,
        formData
      );
      if (response.status === 200) {
        navigate("/page/thank-you");
      }
    } catch (error) {
      alert("Failed to submit request");
    }
  };

  return (
    <div className="pricing-request">
      <Helmet>
        <title>{formatTitle(t("pricing_request"))}</title>
      </Helmet>
      <h1 className="pricing-title blue-color">{t("pricing_request")}</h1>
      <p className="pricing-description">
        {t("form.complete_pricing_request_form")}
      </p>
      <form className="pricing-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="firstName">{t("form.first_name")} *</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="lastName">{t("form.last_name")} *</label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="workEmail">{t("form.work_email")} *</label>
          <input
            type="email"
            id="workEmail"
            name="workEmail"
            value={formData.workEmail}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="organization">{t("form.organization")} *</label>
          <input
            type="text"
            id="organization"
            name="organization"
            value={formData.organization}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="annualSales">
            {t("form.estimated_annual_sales")} *
          </label>
          <input
            type="text"
            id="annualSales"
            name="annualSales"
            value={formData.annualSales}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="website">{t("form.website_url")}</label>
          <input
            type="url"
            id="website"
            name="website"
            value={formData.website}
            onChange={handleChange}
            onFocus={handleFocus}
            placeholder="https://your-web-site-here"
          />
        </div>
        <div className="form-group">
          <label htmlFor="phone">{t("form.phone_number")}</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
          />
        </div>
        <button type="submit" className="submit-button blue-bk">
          {t("form.get_pricing")}
        </button>
      </form>
    </div>
  );
};

export default PricingRequestPage;
