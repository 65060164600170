import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./DemoRequestPage.css";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import formatTitle from "../utils/formatTitle";

const DemoRequestPage = () => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    organization: "",
    jobTitle: "",
    phoneNumber: "",
    country: "",
    currentSoftwareProviders: "",
    hearAboutUs: "",
    assist: "",
  });
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();
  const [charCount, setCharCount] = useState(0);
  const [wordCount, setWordCount] = useState(0);
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "hearAboutUs") {
      if (value.length <= 1000) {
        setFormData({
          ...formData,
          [name]: value,
        });
        setCharCount(value.length);
      }
    } else if (name === "assist") {
      const wordArray = value.trim().split(/\s+/);
      if (wordArray.length <= 300) {
        setFormData({
          ...formData,
          [name]: value,
        });
        setWordCount(wordArray.length);
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${backendUrl}/v1/demo-request`,
        formData
      );
      if (response.status === 200) {
        navigate("/learn-more?submitted=true");
      }
    } catch (error) {
      alert("Failed to submit request");
    }
  };

  return (
    <div className="demo-request">
      <Helmet>
        <title>{formatTitle(t('request_a_demo'))}</title>
      </Helmet>
      <h1 className="demo-title blue-color">{t("request_a_demo")}</h1>
      <p className="demo-description">{t("please_fill_demo_request_form")}</p>
      <form className="demo-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="firstName">{t("form.first_name")} *</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="lastName">{t("form.last_name")} *</label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">{t("form.email")} *</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="organization">{t("form.organization")} *</label>
          <input
            type="text"
            id="organization"
            name="organization"
            value={formData.organization}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="title">{t("form.job_title")}</label>
          <input
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="phone">{t("form.phone_number")}</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="country">{t("form.country")}</label>
          <input
            type="text"
            id="country"
            name="country"
            value={formData.country}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="currentSoftwareProviders">
            {t("form.current_software_providers")}
          </label>
          <textarea
            name="currentSoftwareProviders"
            value={formData.currentSoftwareProviders}
            onChange={handleChange}
            rows="4"
            maxLength="1000"
          />
        </div>
        <div className="form-group">
          <label htmlFor="hearAboutUs">
            {t("form.how_did_you_hear_about_us")}
          </label>
          <textarea
            name="hearAboutUs"
            value={formData.hearAboutUs}
            onChange={handleChange}
            rows="5"
            maxLength="1000"
          />
          <div>{t("form.max_characters", { count: 1000, charCount })}</div>
        </div>
        <div className="form-group">
          <label htmlFor="assist">{t("form.how_can_we_assist_you")}</label>
          <textarea
            name="assist"
            value={formData.assist}
            onChange={handleChange}
            rows="5"
            maxLength="3000"
          />
          <div>{t("form.max_words", { count: 300, wordCount })}</div>
        </div>

        <button type="submit" className="submit-button blue-bk">
          {t("form.submit")}
        </button>
      </form>
    </div>
  );
};

export default DemoRequestPage;
