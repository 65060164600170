import React from "react";
import "./MoreFeaturesSection.css";
import { useTranslation } from "react-i18next";

const MoreFeaturesSection = ({ showMore, toggleShowMore }) => {
  const { t } = useTranslation();
  const moreFeatures = t("more_features", { returnObjects: true });

  return (
    <div className="more-features-section blue-bk">
      <div className="content">
        <h2 className="more-features-title golden">{t('more_key_features')}</h2>
        <div className="more-features-grid">
          {moreFeatures
            .slice(0, showMore ? moreFeatures.length : 6)
            .map((feature, index) => (
              <div className="feature" key={index}>
                <span className="feature-icon">
                  <img
                    src="/images/check-mark.svg"
                    alt="check-mark"
                    className="check-mark-image"
                    width="24px"
                  />
                </span>
                <div className="feature-content">
                  <h3 className="feature-title golden">{feature.title}</h3>
                  <p className="feature-text">{feature.description}</p>
                </div>
              </div>
            ))}
        </div>
        <div className="view-all" onClick={toggleShowMore}>
          {showMore ? "View Less" : "View All"}
        </div>
      </div>
    </div>
  );
};

export default MoreFeaturesSection;
