import React from "react";

import ContentSection from "./ContentSection";
import FeaturesSection from "./FeaturesSection";
import MoreFeaturesSection from "./MoreFeaturesSection";
import NumbersSection from "./NumbersSection";
import TestimonialsSection from "./TestimonialsSection";
import { Helmet } from "react-helmet";
import config from "../../config";

const HomePage = () => {
  const [showMore, setShowMore] = React.useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <div className="home-page">
      <Helmet>
        <title>{config.siteName}</title>
      </Helmet>
      <ContentSection />
      <NumbersSection />
      <FeaturesSection />
      <MoreFeaturesSection
        showMore={showMore}
        toggleShowMore={toggleShowMore}
      />
      <TestimonialsSection />
    </div>
  );
};

export default HomePage;
