import React from "react";
import { Link } from "react-router-dom";
import TypingEffect from "./TypingEffect";
import "./ContentSection.css";
import { useTranslation } from "react-i18next";

const ContentSection = () => {
  const { t } = useTranslation();

  return (
    <div className="content content-section">
      <div className="left-section">
        <h1 className="title">{t("supercharge_your_box_office_with")}</h1>
        <TypingEffect />
        <p className="content-text">{t("ticketingbox_delivers")}</p>
        <div className="buttons">
          <Link to="/learn-more" className="link-button">
            {t("learn_more")}
          </Link>
          <Link to="/demo-request" className="link-button">
            {t("request_a_demo")}
          </Link>
        </div>
      </div>
      <div className="right-section">&nbsp;</div>
    </div>
  );
};

export default ContentSection;
