import React from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { t } = useTranslation();

  return (
    <div className="header-section blue-bk">
      <div className="content header-content">
        <Link to="/" className="logo">
          <img src="/images/logo.svg" alt="Logo" />
        </Link>
        <nav className="header-links">
          <Link to="/pricing-request">{t("pricing")}</Link>
          <Link to="/demo-request">{t("request_a_demo")}</Link>
        </nav>
      </div>
    </div>
  );
};

export default Header;
