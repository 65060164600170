import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { Helmet } from "react-helmet";
import "./MarkdownStyles.css";

const StaticPage = ({ fileName, pageTitle }) => {
  const [content, setContent] = useState("");

  useEffect(() => {
    fetch(fileName)
      .then((response) => response.text())
      .then((text) => setContent(text));
  }, [fileName]);

  return (
    <div className="content">
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <ReactMarkdown className="markdown">
        {content}
      </ReactMarkdown>
    </div>
  );
};

export default StaticPage;
