import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import StaticPage from "./StaticPage";

import formatTitle from "../utils/formatTitle";

const PageWrapper = ({ allowedPages }) => {
  const { pageName } = useParams();
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  // Validate and sanitize the pageName
  if (!allowedPages.includes(pageName)) {
    return <div>Page not found</div>;
  }

  const pageTitles = {
    "about-us": t("page_title.about_us"),
    "privacy-policy": t("page_title.privacy_policy"),
    "terms-and-conditions": t("page_title.terms_conditions"),
    "cookie-policy": t("page_title.cookie_policy"),
    "contact-us": t("page_title.contact_us"),
  };

  const language = i18n.language;
  const fileName = `/content/${language}/${pageName}.md`;
  const pageTitle = formatTitle(pageTitles[pageName]);
  return <StaticPage fileName={fileName} pageTitle={pageTitle} />;
};

export default PageWrapper;
