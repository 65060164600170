import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import "./index.css"; // Optional: Import your custom CSS
import App from "./App"; // Import the main App component
import './locales/i18n';
import reportWebVitals from "./reportWebVitals"; // Optional: For performance measuring
import ReactGA from 'react-ga4';

// Initialize Google Analytics with the Measurement ID from the environment variable
const measurementId = process.env.REACT_APP_GA_MEASUREMENT_ID;
if (measurementId) {
  ReactGA.initialize(measurementId);
  ReactGA.send("pageview");
} else {
  console.error('Google Analytics Measurement ID is not set.');
}

const root = ReactDOM.createRoot(document.getElementById("root")); // Create a root for ReactDOM rendering
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals(); // Optional: If you are using web vitals for performance measuring
