// src/components/Footer.js
import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const { t } = useTranslation();

  return (
    <footer className="footer-section blue-bk">
      <div className="content footer-content">
        <Link to="/" className="logo">
          <img src="/images/logo.svg" alt="Logo" />
        </Link>
        <nav className="footer-links">
          <ul>
            <li>
              <Link to="/page/about-us" className="footer-link">
                {t("page_title.about_us")}
              </Link>
            </li>
            <li>
              <Link to="/page/contact-us" className="footer-link">
                {t("page_title.contact_us")}
              </Link>
            </li>
            <li>
              <Link to="/page/terms-and-conditions" className="footer-link">
                {t("page_title.terms_conditions")}
              </Link>
            </li>
            <li>
              <Link to="/page/privacy-policy" className="footer-link">
                {t("page_title.privacy_policy")}
              </Link>
            </li>
            <li>
              <Link to="/page/cookie-policy" className="footer-link">
                {t("page_title.cookie_policy")}
              </Link>
            </li>
          </ul>
        </nav>
      </div>
      <div className="content">
        <div className="footer-copyright">
          &copy; {currentYear} Ticketingbox {t("all_rights_reserved")}
        </div>
      </div>
    </footer>
  );
};
export default Footer;
