import React from "react";
import "./NumbersSection.css";
import { useTranslation } from "react-i18next";

const NumbersSection = () => {
  const { t } = useTranslation();

  return (
  <div className="numbers-section blue-bk">
    <div className="content">
      <div className="number">
        <div className="highlight golden">10.5 {t('million')}</div>
        <div>{t('tickets_sold')}</div>
      </div>
      <div className="number">
        <div className="highlight golden">4.2 {t('million')}</div>
        <div>{t('transactions_processed')}</div>
      </div>
      <div className="number">
        <div className="highlight golden">6k+</div>
        <div>{t('events_published')}</div>
      </div>
      <div className="number">
        <div className="highlight golden">400+</div>
        <div>{t('venues_served')}</div>
      </div>
    </div>
  </div>
);
};

export default NumbersSection;
