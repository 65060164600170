import React, { useEffect, useState } from "react";
import "./TypingEffect.css";
import { useTranslation } from "react-i18next";

const TypingEffect = () => {
  const typingSpeed = 30; // milliseconds
  const pauseTime = 3000;

  const [text, setText] = useState("");
  const [isTyping, setIsTyping] = useState(true);
  const [sentenceIndex, setSentenceIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    const sentences = t("typing", { returnObjects: true });

    if (charIndex < sentences[sentenceIndex].length) {
      const timeoutId = setTimeout(() => {
        setText((prev) => prev + sentences[sentenceIndex].charAt(charIndex));
        setCharIndex(charIndex + 1);
      }, typingSpeed);

      return () => clearTimeout(timeoutId);
    } else {
      setIsTyping(false);
      const pauseTimeout = setTimeout(() => {
        setIsTyping(true);
        setText("");
        setCharIndex(0);
        setSentenceIndex((prev) => (prev + 1) % sentences.length);
      }, pauseTime);

      return () => clearTimeout(pauseTimeout);
    }
  }, [charIndex, isTyping, sentenceIndex, t]);

  return (
    <div className="typing-container">
      <span id="typed-text">{text}</span>
      {isTyping && <span className="cursor"></span>}
    </div>
  );
};

export default TypingEffect;
