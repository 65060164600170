import React from "react";
import "./FeaturesSection.css";
import { useTranslation } from "react-i18next";

const FeaturesSection = () => {
  const { t } = useTranslation();
  const features = t("features", { returnObjects: true });

  return (
    <div className="features-section">
      <div className="content">
        <h2 className="features-title blue-color">
          Your New Turn-Key Ticketing Platform
        </h2>
        <div className="features-grid">
          {features.map((feature, index) => (
            <div className="feature-block" key={index}>
              <img
                src={`/images/${feature.image}`}
                alt={feature.title}
                className="feature-image"
              />
              <h3 className="feature-title">{feature.title}</h3>
              <p className="feature-description">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeaturesSection;
