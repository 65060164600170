import React, {useEffect} from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./components/HomePage/HomePage";
import PricingRequestPage from "./components/PricingRequestPage";
import DemoRequestPage from "./components/DemoRequestPage";
import LearnMorePage from "./components/LearnMorePage";
import "./locales/i18n";
import "./App.css";
import Layout from "./components/Layout";
import PageWrapper from "./components/PageWrapper";
import usePageTracking from "./usePageTracking";
import TagManager from 'react-gtm-module';

const allowedPages = [
  "about-us",
  "contact-us",
  "cookie-policy",
  "privacy-policy",
  "terms-and-conditions",
  "thank-you",
];

function App() {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GTM_ID
    };

    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <Router>
      <TrackedRoutes />
    </Router>
  );
}

function TrackedRoutes() {
  usePageTracking();

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/pricing-request" element={<PricingRequestPage />} />
        <Route path="/demo-request" element={<DemoRequestPage />} />
        <Route path="/learn-more" element={<LearnMorePage />} />
        <Route
          path="/page/:pageName"
          element={<PageWrapper allowedPages={allowedPages} />}
        />
      </Routes>
    </Layout>
  );
}

export default App;
